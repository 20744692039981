import React, { useEffect, useState } from 'react'
import { Col, Label } from 'reactstrap'

//flatpickr
import 'flatpickr/dist/themes/material_blue.css'
import { ProductVN, ManageShopVN, CommonVN } from 'constants/vn'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { MAX_LENGTH_SEARCH, StatusActivationOptions, TypePortfolioOptions } from 'constants/constants'
import { FormSelect } from './form-select/FormSelect'
import { getPortfolioAllAPI } from 'helpers/backend_helper'
import { FormInput } from './form-input/FormInput'
import { FormDateRange } from './form-date-range/FormDateRange'
import { getStorage } from 'helpers/global_helper'

const ProductActivationFilter = ({ setGlobalFilter, search, setSearch }) => {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    zone: app.zone
  }))
  const { zone } = useSelector(LayoutProperties)

  const [filter, setFilter] = useState({})

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }
  const [industry, setIndustry] = useState()
  const [industryOptions, setIndustryOptions] = useState()
  const [industrySector, setIndustrysector] = useState()
  const [industrySectorOptions, setIndustrySectorOptions] = useState()

  const getDataOptions = async () => {
    try {
      const resIndustry = await getPortfolioAllAPI({ type: 'industry' })
      const resIndustrysector = await getPortfolioAllAPI({ type: 'industrySector' })
      setIndustrysector(resIndustrysector?.data)

      setIndustry(resIndustry?.data)
      if (resIndustry?.data?.length > 0) {
        setIndustryOptions(
          [{ label: 'Tất cả', value: 'all' }].concat(
            resIndustry?.data?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )
        )
      } else {
        setIndustryOptions([])
      }

      setIndustrysector(resIndustrysector?.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getDataOptions()
    const filterMangeProductActivation = getStorage('filterManageProductActivation')
    setFilter(filterMangeProductActivation)
  }, [])

  return (
    <React.Fragment>
      <Col xxl={3} lg={4} xs={6} className='mt-5'>
        <Label className='form-label'>Nhập Mã bảo hành</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập Mã bảo hành'
          value={filter?.search_key || ''}
          name='search_key'
          id={`input-filter`}
          onChange={(e) => handleSelect(e.target.value, 'search_key')}
          onBlur={(e) => handleSelect(e.target.value, 'search_key')}
        />
      </Col>

      {/* <Col xxl={3} lg={4} xs={6} className='mt-5'>
        <Label className='form-label'>{ProductVN.filter?.industrySector.label}</Label>
        <FormSelect
          options={industrySectorOptions}
          value={filter?.industry_sector_ids}
          onChange={(value, label) => {
            handleSelect(value, 'industry_sector_ids')
            handleSelect(
              label.map((e) => e.label),
              'industry_sector_names'
            )
          }}
          onSelect={(value) => {
            const all = industrySector?.map((item) => item.id)
            const all_names = industrySector?.map((item) => item.name)

            if (value && value === 'all') {
              handleSelect([...all], 'industry_sector_ids')
              handleSelect([...all_names], 'industry_sector_names')
            }
          }}
          placeholder={ProductVN.filter?.industrySector.placeholder}
          mode='multiple'
        />
      </Col> */}

      <Col xxl={3} lg={4} xs={6} className='mt-5'>
        <Label className='form-label'>Ngành</Label>
        <FormSelect
          options={industryOptions}
          onChange={(value, label) => {
            handleSelect(value, 'industry_ids')
            handleSelect(
              'industry_names',
              label.map((e) => e.label)
            )

            handleSelect([], 'industry_sector_ids')
            handleSelect([], 'category_ids')
            handleSelect([], 'model_ids')
            handleSelect([], 'industry_sector_names')
            handleSelect([], 'category_names')
            handleSelect([], 'model_names')
          }}
          onSelect={(value) => {
            const all = industry?.map((item) => item.id)
            const all_names = industry?.map((item) => item.name)
            if (value && value === 'all') {
              handleSelect([...all], 'industry_ids')
              handleSelect([...all_names], 'industry_names')
            }
          }}
          value={filter?.industry_ids}
          placeholder='Chọn Ngành'
          mode='multiple'
        />
      </Col>

      <Col xxl={3} lg={4} xs={6} className='mt-5'>
        <Label className='form-label'>Ngành hàng</Label>
        <FormSelect
          options={
            industrySector?.filter((e) => filter?.industry_ids?.includes(e.parentId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  industrySector
                    ?.filter((e) => filter?.industry_ids?.includes(e.parentId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          onChange={(value, label) => {
            handleSelect(value, 'industry_sector_ids')
            handleSelect(
              label.map((e) => e.label),
              'industry_sector_names'
            )

            handleSelect([], 'category_ids')
            handleSelect([], 'model_ids')
            handleSelect([], 'category_names')
            handleSelect([], 'model_names')
          }}
          onSelect={(value) => {
            const all = industrySector
              ?.filter((e) => filter?.industry_ids?.includes(e.parentId))
              ?.map((item) => item.id)
            const all_names = industrySector
              ?.filter((e) => filter?.industry_ids?.includes(e.parentId))
              ?.map((item) => item.name)

            if (value && value === 'all') {
              handleSelect([...all], 'industry_sector_ids')
              handleSelect([...all_names], 'industry_sector_names')
            }
          }}
          value={filter?.industry_sector_ids}
          placeholder='Chọn Ngành hàng'
          mode='multiple'
        />
      </Col>

      <Col xxl={3} lg={4} xs={6} className='mt-5'>
        <Label className='form-label'>{ProductVN.filter?.status.label}</Label>
        <FormSelect
          options={StatusActivationOptions?.map((item) => ({
            value: item.value,
            label: item.label
          }))}
          value={filter?.status}
          onChange={(value, label) => {
            handleSelect(value, 'status')
            handleSelect(label?.label, 'status_name')
          }}
          placeholder={ProductVN.filter?.status.placeholder}
        />
      </Col>

      <Col xxl={3} lg={4} xs={6} className='mt-5'>
        <Label className='form-label'>Thời gian áp dụng</Label>
        <FormDateRange
          className='w-100'
          size='large'
          format={CommonVN.datePlaceholder.toUpperCase()}
          placeholder={[CommonVN.datePlaceholder, CommonVN.datePlaceholder]}
          value={[filter?.start_date1, filter?.end_date1]}
          onChange={(value) => {
            if (value) {
              handleSelect(value[0], 'start_date1')
              handleSelect(value[1], 'end_date1')
              handleSelect(value[0], 'start_date')
              handleSelect(value[1], 'end_date')
            } else {
              handleSelect(undefined, 'start_date1')
              handleSelect(undefined, 'end_date1')
              handleSelect(undefined, 'start_date')
              handleSelect(undefined, 'end_date')
            }
          }}
        />
      </Col>

      <Col xxl={2} lg={4} xs={6} className='d-flex align-items-end justify-content mt-5'>
        <div className='mb-xxl-0 me-2'>
          <button
            type='button'
            className='btn btn-soft-danger w-100'
            onClick={() => {
              setFilter({
                industry_sector_ids: undefined,
                status: undefined,
                start_date1: undefined,
                end_date1: undefined,
                start_date: undefined,
                end_date: undefined
              })
              setSearch(undefined)
              setGlobalFilter({})
            }}
          >
            Xóa tìm kiếm
          </button>
        </div>
        <div className='mb-xxl-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={() => {
              const _filter = !filter
                ? filter
                : Object.fromEntries(Object.entries(filter).filter(([key, value]) => value !== undefined))
              setGlobalFilter({ ..._filter, search })
            }}
          >
            Tìm kiếm
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default ProductActivationFilter
