import React, { useEffect, useState } from 'react'
import { Col, Label, Row } from 'reactstrap'

//flatpickr
import 'flatpickr/dist/themes/material_blue.css'
import { ActivationVN, CommonVN, LocalityVN, ManageContentVN } from 'constants/vn'
import { FormSelect } from './form-select/FormSelect'
import { AREA_TYPE } from 'pages/ManageLocality/constants'
import { getZoneAPI, getAreaManagementOptionsAPI } from 'helpers/backend_helper'
import { DatePicker } from 'antd'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { MAX_LENGTH_SEARCH, TypeContentOptions, TypeProgramOptions } from 'constants/constants'
import { FormInput } from './form-input/FormInput'
import { FormDateRange } from './form-date-range/FormDateRange'
import { getStorage } from 'helpers/global_helper'
const { RangePicker } = DatePicker
const NotificationGlobalFilter = ({ setGlobalFilter, search, setSearch }) => {
  const [filter, setFilter] = useState({})
  const [zone, setZone] = useState([])
  const [subZone, setSubZone] = useState([])
  const [valueFilter, setValueFilter] = useState()
  const [selectedZone, setSelectedZone] = useState()
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    province: app.province,
    district: app.district,
    ward: app.ward
  }))
  const { province } = useSelector(LayoutProperties)

  useEffect(() => {
    handleGetAllZone()
    const filterManageNotification = getStorage('filterManageNotification')
    setFilter(filterManageNotification)
  }, [])

  useEffect(() => {
    if (selectedZone) {
      handleGetSubZone()
    }
  }, [selectedZone])

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }

  const handleGetAllZone = async () => {
    const res = await getZoneAPI()

    if (res.data) {
      res.data.map((item) => {
        setZone((e) => [...e, { value: item.id, label: item.name }])
      })
    }
  }

  const handleGetSubZone = async () => {
    const res = await getAreaManagementOptionsAPI({ type: AREA_TYPE.SUBZONE, parent_id: selectedZone })

    if (res.data) {
      const list_data = res.data.map((item) => {
        return {
          value: item.id,
          label: item.name
        }
      })

      setSubZone(list_data)
    }
  }

  return (
    <React.Fragment>
      <Col className='mt-3' xxl={3} lg={6}>
        <Label className='form-label'>{'Tài khoản nhận thông báo'}</Label>
        <FormInput
          maxLength={MAX_LENGTH_SEARCH}
          value={filter?.phone || ''}
          placeholder={'Nhập số điện thoại'}
          onChange={(e) => {
            // setValueFilter(e.target.value)
            handleSelect(e.target.value.trim(), 'phone')
          }}
          onBlur={(e) => {
            setValueFilter(e.target.value.trim())
          }}
        />
      </Col>
      <Col xxl={2} lg={2} className='mt-3'>
        <Label className='form-label'>{'Trạng thái'}</Label>
        <FormSelect
          options={[
            {
              label: 'Chưa gửi',
              value: 'pending'
            },
            {
              label: 'Đã gửi',
              value: 'success'
            },
            {
              label: 'Không thành công',
              value: 'failed'
            },
            {
              label: 'Đã huỷ',
              value: 'cancelled'
            }
          ]}
          value={filter?.type}
          onChange={(value) => {
            handleSelect(value, 'status')
          }}
          placeholder='Tất cả'
        />
      </Col>

      <Col xxl={3} className='mt-3' lg={3}>
        <Label className='form-label'>{'Thời gian gửi'}</Label>
        <FormDateRange
          className='w-100'
          size='large'
          format={CommonVN.datePlaceholder.toUpperCase()}
          placeholder={[CommonVN.datePlaceholder, CommonVN.datePlaceholder]}
          value={[filter?.start_date, filter?.end_date]}
          onChange={(value) => {
            if (value) {
              handleSelect(value[0], 'start_date')
              handleSelect(value[1], 'end_date')
            } else {
              handleSelect(undefined, 'start_date')
              handleSelect(undefined, 'end_date')
            }
          }}
        />
      </Col>

      {/* <Col xxl={1} lg={1} className="mt-3" /> */}
      <Col xxl={2} lg={2} className='d-flex align-items-end justify-content-end mt-3 '>
        <div className='mb-xxl-0 me-2'>
          <button
            type='button'
            className='btn btn-soft-danger w-100'
            onClick={() => {
              setFilter({
                status: undefined,
                start_date: undefined,
                end_date: undefined,
                phone: ''
              })
              setSearch('')
              setGlobalFilter({})
            }}
          >
            Xóa tìm kiếm
          </button>
        </div>
        <div className='mb-xxl-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={() => {
              const _filter = !filter
                ? filter
                : Object.fromEntries(Object.entries(filter).filter(([key, value]) => value !== undefined))
              setGlobalFilter({ ..._filter, search })
            }}
          >
            Tìm kiếm
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default NotificationGlobalFilter
