import React, { useEffect, useState } from 'react'
import { Col, Label } from 'reactstrap'

//flatpickr
import 'flatpickr/dist/themes/material_blue.css'
import { LocalityVN, ProductVN } from 'constants/vn'
import { FormSelect } from './form-select/FormSelect'
import { optionsGeographicalLevel, AREA_TYPE } from 'pages/ManageLocality/constants'
import { getZoneAPI, getAreaManagementOptionsAPI } from 'helpers/backend_helper'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { getStorage } from 'helpers/global_helper'

const LocalityListGlobalFilter = ({ setGlobalFilter, search, setSearch }) => {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    zone: app.zone,
    subzone: app.subzone,
    province: app.province,
    district: app.district,
    ward: app.ward,
    myLocationManagement: app.myLocationManagement
  }))
  const { zone, subzone, province, district, ward, myLocationManagement } = useSelector(LayoutProperties)

  const [filter, setFilter] = useState({})
  //   const [zone, setZone] = useState([])
  const [subZone, setSubZone] = useState([])
  const [selectedZone, setSelectedZone] = useState()
  const lstDistrict = myLocationManagement

  //   useEffect(() => {
  //     handleGetAllZone()
  //   }, [])

  //   useEffect(() => {
  //     if (selectedZone) {
  //       handleGetSubZone()
  //     }
  //   }, [selectedZone])

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }

  useEffect(() => {
    const filterManageLocality = getStorage('filterManageLocality')
    setFilter(filterManageLocality)
  }, [])

  ////Lấy danh sách ward
  // Get all unique parentId values from lstDistrict
  const wardParentIds = myLocationManagement?.map((district) => district.id)

  //Từ wardParentIds, lấy ra những ward có id trong wardParentIds
  const lstWard = ward.filter((ward) => wardParentIds.includes(ward.parentId))

  ////Lấy danh sách province
  // Get all unique parentId values from lstDistrict
  const districtParentIds = lstDistrict?.map((district) => district.parentId)
  //Từ districtParentIds, lấy ra những district có id trong districtParentIds
  const lstProvionce = province.filter((province) => districtParentIds.includes(province.id))

  ////Lấy danh sách subZone
  // Get all unique subZoneId(parentId) values from lstProvionce
  const provinceParentIds = lstProvionce?.map((province) => province.subZoneId)
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstSubZone = subzone?.filter((subZone) => provinceParentIds.includes(subZone.id))

  ////Lấy danh sách zone
  // Get all unique zoneId(parentId) values from lstProvionce
  const subZoneParentIds = lstProvionce?.map((province) => province.zoneId) // trong lstProvionce có lưu zoneId
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstZone = zone.filter((zone) => subZoneParentIds.includes(zone.id))

  return (
    <React.Fragment>
      <Col xxl={2} lg={2} className='mt-3'>
        <Label className='form-label'>{LocalityVN.filters.geographicalLevel.title}</Label>
        <FormSelect
          options={optionsGeographicalLevel}
          value={filter?.type}
          onChange={(value) => {
            handleSelect(value, 'type')
            handleSelect(undefined, 'zone_ids')
            handleSelect(undefined, 'sub_zone_ids')
          }}
          placeholder={LocalityVN.filters.geographicalLevel.placeholder}
        />
      </Col>

      <Col xxl={2} lg={2} className='mt-3'>
        <Label className='form-label'>Miền</Label>
        <FormSelect
          options={[{ label: 'Tất cả', value: 'all' }].concat(
            lstZone?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )}
          value={filter?.zone_ids}
          onChange={(value) => {
            handleSelect(value, 'zone_ids')
            handleSelect([], 'sub_zone_ids')
            handleSelect([], 'province_ids')
            handleSelect([], 'district_ids')
            handleSelect([], 'ward_ids')
          }}
          onSelect={(value) => {
            const all = lstZone?.map((item) => item.id)
            if (value && value === 'all') {
              return handleSelect([...all], 'zone_ids')
            }
          }}
          mode='multiple'
          placeholder={ProductVN.filter?.zone.placeholder}
        />
      </Col>

      <Col xxl={2} lg={2} className='mt-3'>
        <Label className='form-label'>{'Vùng'}</Label>
        <FormSelect
          options={
            lstSubZone?.filter((e) => filter?.zone_ids?.includes(e.zoneId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  lstSubZone
                    ?.filter((e) => filter?.zone_ids?.includes(e.zoneId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter?.sub_zone_ids}
          onChange={(value) => {
            handleSelect(value, 'sub_zone_ids')
            handleSelect(undefined, 'province_ids')
            handleSelect(undefined, 'location_ids')
            handleSelect(undefined, 'ward_ids')
          }}
          onSelect={(value) => {
            const all = lstSubZone?.filter((e) => filter?.zone_ids?.includes(e.zoneId))?.map((item) => item.id)
            if (value && value === 'all') {
              return handleSelect([...all], 'sub_zone_ids')
            }
          }}
          placeholder={'Chọn vùng'}
          mode='multiple'
        />
      </Col>

      <Col xxl={2} lg={2} className='mt-3'>
        <Label className='form-label'>Tỉnh/ Thành phố</Label>
        <FormSelect
          options={
            lstProvionce?.filter((e) => filter?.sub_zone_ids?.includes(e.subZoneId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  lstProvionce
                    ?.filter((e) => filter?.sub_zone_ids?.includes(e.subZoneId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter?.province_ids}
          onChange={(value, label) => {
            handleSelect(value, 'province_ids')
            handleSelect(label.map((e) => e.label, 'province_names'))

            handleSelect([], 'district_ids')
            handleSelect([], 'ward_ids')
            handleSelect([], 'district_names')
            handleSelect([], 'ward_names')
          }}
          onSelect={(value) => {
            const all = lstProvionce?.filter((e) => filter?.sub_zone_ids?.includes(e.subZoneId))?.map((item) => item.id)
            const all_names = lstProvionce
              ?.filter((e) => filter?.sub_zone_ids?.includes(e.subZoneId))
              ?.map((item) => item.name)

            if (value && value === 'all') {
              handleSelect([...all], 'province_ids')
              handleSelect([...all_names], 'province_names')
            }
          }}
          placeholder='Tất cả'
          mode='multiple'
        />
      </Col>

      <Col xxl={2} lg={2} className='mt-3'>
        <Label className='form-label'>{'Quận/ Huyện'}</Label>
        <FormSelect
          options={
            lstDistrict?.filter((e) => filter?.province_ids?.includes(e.parentId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  lstDistrict
                    ?.filter((e) => filter?.province_ids?.includes(e.parentId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter?.district_ids}
          onChange={(value, label) => {
            handleSelect(value, 'district_ids')
            handleSelect(
              label.map((e) => e.label),
              'district_names'
            )
            handleSelect([], 'ward_ids')
            handleSelect([], 'ward_names')
          }}
          onSelect={(value) => {
            const all = lstDistrict?.filter((e) => filter?.province_ids?.includes(e.parentId))?.map((item) => item.id)
            const all_names = lstDistrict
              ?.filter((e) => filter?.province_ids?.includes(e.parentId))
              ?.map((item) => item.name)

            if (value && value === 'all') {
              handleSelect([...all], 'district_ids')
              handleSelect([...all_names], 'district_names')
            }
          }}
          placeholder='Tất cả'
          mode='multiple'
        />
      </Col>

      <Col xxl={2} lg={4} className='d-flex align-items-end justify-content mt-3 '>
        <div className='mb-xxl-0 me-2'>
          <button
            type='button'
            className='btn btn-soft-danger w-100'
            onClick={() => {
              setFilter({
                status: undefined,
                zone_ids: undefined,
                zone_names: undefined,
                sub_zone_ids: undefined,
                sub_zone_names: undefined,
                province_ids: undefined,
                province_names: undefined,
                district_ids: undefined,
                district_names: undefined
              })
              setSearch('')
              setGlobalFilter({})
            }}
          >
            Xóa tìm kiếm
          </button>
        </div>
        <div className='mb-xxl-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={() => {
              const _filter = !filter
                ? filter
                : Object.fromEntries(Object.entries(filter).filter(([key, value]) => value !== undefined))
              setGlobalFilter({ ..._filter, search })
            }}
          >
            Tìm kiếm
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default LocalityListGlobalFilter
