import React, { useEffect, useState } from 'react'
import { Col, Label } from 'reactstrap'

//flatpickr
import 'flatpickr/dist/themes/material_blue.css'
import { ClassStructureVN, CommonVN, ProductVN } from 'constants/vn'
import { FormSelect } from './form-select/FormSelect'
import { OptionTypeAccount } from '../../pages/ManageAccountClass/constants/index'
import { FormDateRange } from './form-date-range/FormDateRange'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { getPortfolioAllAPI, getRankListAPI } from 'helpers/backend_helper'
import { getStorage } from 'helpers/global_helper'

const ClassStructureGlobalFilter = ({ setGlobalFilter, search, setSearch, optionPermissionGroup }) => {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    zone: app.zone,
    subzone: app.subzone,
    province: app.province,
    district: app.district,
    ward: app.ward,
    myLocationManagement: app.myLocationManagement
  }))
  const { zone, subzone, province, district, myLocationManagement, ward } = useSelector(LayoutProperties)
  const [industry, setIndustry] = useState()
  const [industryOptions, setIndustryOptions] = useState()
  const [industrySector, setIndustrysector] = useState()
  const [category, setCategory] = useState()
  const [model, setModel] = useState()
  const [rank, setRank] = useState()
  const [rankOptions, setRankOptions] = useState()

  const [filter, setFilter] = useState({})

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }

  const onFilterChange = (name, value) =>
    setFilter((prev) => ({
      ...prev,
      [name]: value
    }))

  useEffect(() => {
    getIndustryDataOptions()

    const filterManageClassStructure = getStorage('ManageClassStructure')
    setFilter(filterManageClassStructure)
  }, [])

  const lstDistrict = myLocationManagement

  ////Lấy danh sách ward
  // Get all unique parentId values from lstDistrict
  const wardParentIds = myLocationManagement?.map((district) => district.id)

  //Từ wardParentIds, lấy ra những ward có id trong wardParentIds
  const lstWard = ward.filter((ward) => wardParentIds.includes(ward.parentId))

  ////Lấy danh sách province
  // Get all unique parentId values from lstDistrict
  const districtParentIds = lstDistrict?.map((district) => district.parentId)
  //Từ districtParentIds, lấy ra những district có id trong districtParentIds
  const lstProvionce = province.filter((province) => districtParentIds.includes(province.id))

  ////Lấy danh sách subZone
  // Get all unique subZoneId(parentId) values from lstProvionce
  const provinceParentIds = lstProvionce?.map((province) => province.subZoneId)
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstSubZone = subzone?.filter((subZone) => provinceParentIds.includes(subZone.id))

  ////Lấy danh sách zone
  // Get all unique zoneId(parentId) values from lstProvionce
  const subZoneParentIds = lstProvionce?.map((province) => province.zoneId) // trong lstProvionce có lưu zoneId
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstZone = zone.filter((zone) => subZoneParentIds.includes(zone.id))

  const getIndustryDataOptions = async () => {
    try {
      const resIndustry = await getPortfolioAllAPI({ type: 'industry' })
      const resIndustrysector = await getPortfolioAllAPI({ type: 'industrySector' })
      const resCategory = await getPortfolioAllAPI({ type: 'category' })
      const resModel = await getPortfolioAllAPI({ type: 'model' })
      const resRank = await getRankListAPI()

      setRank(resRank?.data)

      setIndustry(resIndustry?.data)

      if (resIndustry?.data?.length > 0) {
        setIndustryOptions(
          [{ label: 'Tất cả', value: 'all' }].concat(
            resIndustry?.data?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )
        )
      } else {
        setIndustryOptions([])
      }

      if (resRank?.data?.length > 0) {
        setRankOptions(
          [{ label: 'Tất cả', value: 'all' }].concat(
            resRank?.data?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )
        )
      } else {
        setRankOptions([])
      }

      setIndustrysector(resIndustrysector?.data)
      setCategory(resCategory?.data)
      setModel(resModel?.data)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <React.Fragment>
      <Col xxl={3} lg={3} className='mt-5'>
        <Label className='form-label'>{ClassStructureVN.filters.accoutType.title}</Label>
        <FormSelect
          options={OptionTypeAccount}
          placeholder={ClassStructureVN.filters.accoutType.placeholder}
          value={filter?.client}
          onChange={(value) => {
            handleSelect(value, 'client')
          }}
        />
      </Col>

      <Col xxl={3} lg={3} className='mt-5'>
        <Label className='form-label'>Loại hạng</Label>
        <FormSelect
          options={rankOptions}
          onChange={(value, label) => {
            onFilterChange('rank_ids', value)
            onFilterChange(
              'rank_names',
              label.map((e) => e.label)
            )
          }}
          onSelect={(value) => {
            const all = rank?.map((item) => item.id)
            const all_names = rank?.map((item) => item.name)
            if (value && value === 'all') {
              onFilterChange('rank_ids', [...all])
              onFilterChange('rank_names', [...all_names])
            }
          }}
          value={filter?.rank_ids}
          placeholder='Chọn loại hạng'
          mode='multiple'
        />
      </Col>

      <Col xxl={3} lg={3} className='mt-5'>
        <Label className='form-label'>Ngành</Label>
        <FormSelect
          options={industryOptions}
          onChange={(value, label) => {
            onFilterChange('industry_ids', value)
            onFilterChange(
              'industry_names',
              label.map((e) => e.label)
            )

            onFilterChange('industry_sector_ids', [])
            onFilterChange('category_ids', [])
            onFilterChange('model_ids', [])
            onFilterChange('industry_sector_names', [])
            onFilterChange('category_names', [])
            onFilterChange('model_names', [])
          }}
          onSelect={(value) => {
            const all = industry?.map((item) => item.id)
            const all_names = industry?.map((item) => item.name)
            if (value && value === 'all') {
              onFilterChange('industry_ids', [...all])
              onFilterChange('industry_names', [...all_names])
            }
          }}
          value={filter?.industry_ids}
          placeholder='Chọn Ngành'
          mode='multiple'
        />
      </Col>

      <Col xxl={3} lg={3} className='mt-5'>
        <Label className='form-label'>Ngành hàng</Label>
        <FormSelect
          options={
            industrySector?.filter((e) => filter?.industry_ids?.includes(e.parentId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  industrySector
                    ?.filter((e) => filter?.industry_ids?.includes(e.parentId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          onChange={(value, label) => {
            onFilterChange('industry_sector_ids', value)
            onFilterChange(
              'industry_sector_names',
              label.map((e) => e.label)
            )

            onFilterChange('category_ids', [])
            onFilterChange('model_ids', [])
            onFilterChange('category_names', [])
            onFilterChange('model_names', [])
          }}
          onSelect={(value) => {
            const all = industrySector
              ?.filter((e) => filter?.industry_ids?.includes(e.parentId))
              ?.map((item) => item.id)
            const all_names = industrySector
              ?.filter((e) => filter?.industry_ids?.includes(e.parentId))
              ?.map((item) => item.name)

            if (value && value === 'all') {
              onFilterChange('industry_sector_ids', [...all])
              onFilterChange('industry_sector_names', [...all_names])
            }
          }}
          value={filter?.industry_sector_ids}
          placeholder='Chọn Ngành hàng'
          mode='multiple'
        />
      </Col>

      <Col xxl={3} className='mt-5' lg={3}>
        <Label className='form-label'>Miền</Label>
        <FormSelect
          options={[{ label: 'Tất cả', value: 'all' }].concat(
            lstZone?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )}
          value={filter?.zone_ids}
          onChange={(value, label) => {
            handleSelect(value, 'zone_ids', value)

            handleSelect([], 'sub_zone_ids')
            handleSelect([], 'province_ids')
            handleSelect([], 'district_ids')
            handleSelect([], 'ward_ids')
          }}
          onSelect={(value) => {
            const all = lstZone?.map((item) => item.id)

            if (value && value === 'all') {
              handleSelect([...all], 'zone_ids')
            }
          }}
          placeholder={ProductVN.filter?.zone.placeholder}
          mode='multiple'
        />
      </Col>

      <Col xxl={3} className='mt-5' lg={3}>
        <Label className='form-label'>Vùng</Label>
        <FormSelect
          options={
            lstSubZone?.filter((e) => filter?.zone_ids?.includes(e.zoneId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  lstSubZone
                    ?.filter((e) => filter?.zone_ids?.includes(e.zoneId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter?.sub_zone_ids}
          onChange={(value, label) => {
            handleSelect(value, 'sub_zone_ids')

            handleSelect([], 'province_ids')
            handleSelect([], 'district_ids')
            handleSelect([], 'ward_ids')
          }}
          onSelect={(value) => {
            const all = lstSubZone?.filter((e) => filter?.zone_ids?.includes(e.zoneId))?.map((item) => item.id)

            if (value && value === 'all') {
              handleSelect([...all], 'sub_zone_ids')
            }
          }}
          placeholder={ProductVN.filter?.zone.placeholder}
          mode='multiple'
        />
      </Col>

      <Col xxl={3} className='mt-5' lg={3}>
        <Label className='form-label'>Tỉnh/ Thành phố</Label>
        <FormSelect
          options={
            lstProvionce?.filter((e) => filter?.sub_zone_ids?.includes(e.subZoneId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  lstProvionce
                    ?.filter((e) => filter?.sub_zone_ids?.includes(e.subZoneId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter?.province_ids}
          onChange={(value, label) => {
            handleSelect(value, 'province_ids')

            handleSelect([], 'district_ids')
            handleSelect([], 'ward_ids')
          }}
          onSelect={(value) => {
            const all = lstProvionce?.filter((e) => filter?.sub_zone_ids?.includes(e.subZoneId))?.map((item) => item.id)

            if (value && value === 'all') {
              handleSelect([...all], 'province_ids')
            }
          }}
          placeholder='Tất cả'
          mode='multiple'
        />
      </Col>

      <Col xxl={3} className='mt-5' lg={3}>
        <Label className='form-label'>Quận/ Huyện</Label>
        <FormSelect
          options={
            lstDistrict?.filter((e) => filter?.province_ids?.includes(e.parentId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  lstDistrict
                    ?.filter((e) => filter?.province_ids?.includes(e.parentId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter?.district_ids}
          onChange={(value, label) => {
            handleSelect(value, 'district_ids')
            handleSelect([], 'ward_ids')
          }}
          onSelect={(value) => {
            const all = lstDistrict?.filter((e) => filter?.province_ids?.includes(e.parentId))?.map((item) => item.id)
            if (value && value === 'all') {
              handleSelect([...all], 'district_ids')
            }
          }}
          placeholder='Tất cả'
          mode='multiple'
        />
      </Col>

      <Col xxl={3} className='mt-5' lg={3}>
        <Label className='form-label'>{'Thời gian hạng'}</Label>
        <FormDateRange
          className='w-100'
          size='large'
          format={CommonVN.datePlaceholder.toUpperCase()}
          placeholder={[CommonVN.datePlaceholder, CommonVN.datePlaceholder]}
          value={[filter?.start_date, filter?.end_date]}
          onChange={(value) => {
            if (value) {
              handleSelect(value[0], 'start_date')
              handleSelect(value[1], 'end_date')
            } else {
              handleSelect(undefined, 'start_date')
              handleSelect(undefined, 'end_date')
            }
          }}
        />
      </Col>

      <Col xxl={3} lg={3} className='d-flex align-items-end mt-3'>
        <div className='mb-xxl-0 me-2'>
          <button
            type='button'
            className='btn btn-soft-danger w-100'
            onClick={() => {
              setFilter({
                type: undefined,
                industry_sector_ids: undefined,
                client: undefined,
                start_date: undefined,
                end_date: undefined
              })
              setSearch('')
              setGlobalFilter({})
            }}
          >
            Xóa tìm kiếm
          </button>
        </div>
        <div className='mb-xxl-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={() => {
              const _filter = !filter
                ? filter
                : Object.fromEntries(Object.entries(filter).filter(([key, value]) => value !== undefined))
              setGlobalFilter({ ..._filter, search })
            }}
          >
            Tìm kiếm
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default ClassStructureGlobalFilter
