import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { Col, Row, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import { PortfolioVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import {
  deletePortfolioAPI,
  importFileAPI,
  exportPortfolioAPI,
  downloadTemplateAPI,
  checkUploadFileAPI,
  exportFileError
} from 'helpers/backend_helper'
import {
  ParentPortfolio,
  PortfolioType,
  PortfolioNo,
  PortfolioName,
  CodePortfolio,
  AccumulatePortfolio,
  ActivedPortfolio
} from './ManagePortfolioCol'
import AddEditPortfolio from './components/AddEditPortfolio'
import { getPortfolioDetail, getPortfolioList, setFilter, setTypePopup } from 'store/actions'
import ImportModal from 'components/Common/ImportModal'
import * as url from 'helpers/url_helper'
import moment from 'moment'
import { CATEGORY_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import PermissionWrapper from 'components/Common/PermissionWrapper'
import usePermission from 'hooks/usePermission'
import { getStorage, setStorage } from 'helpers/global_helper'

function ManagePortfolio() {
  //meta title
  document.title = 'Portfolio List | HPG'
  const screenCode = useMemo(() => SCREEN_CODE.CATEGORY_MANAGEMENT, [])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const selectPortfoliosState = (state) => state.PortfolioReducer
  const PortfoliosProperties = createSelector(selectPortfoliosState, (reducer) => ({
    portfolios: reducer.portfolios,
    loading: reducer.loading,
    total: reducer.total
  }))

  const { portfolios, loading, total } = useSelector(PortfoliosProperties)

  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (layout) => ({
    filter: layout.filter
  }))
  const { filter } = useSelector(LayoutProperties)

  const [isLoading, setLoading] = useState(loading)
  const [isLoadingImport, setLoadingImport] = useState(false)
  const [showDeleteAccount, setShowDeleteAccount] = useState(false)
  const [selected, setSelected] = useState()
  const [showForm, setShowForm] = useState(false)
  const [showImport, setShowImport] = useState(false)
  const [page, setPage] = useState(1)
  const hasPermission = usePermission({ screenCode, code: CATEGORY_MANAGEMENT_CODE.PRODUCT_DETAIL_CATEGORY })
  const [dataFilter, setDataFilter] = useState({})

  useEffect(() => {
    // toast("thành công ", { type: "success" })
    const filterMangeShop = getStorage('filterManagePortfolio')
    dispatch(getPortfolioList({ ...filterMangeShop, page: 1 }))
  }, [dispatch])

  const onClickDelete = (item) => {
    setSelected(item)
    setShowDeleteAccount(true)
  }

  const onClickItem = async (item, type) => {
    setSelected(item)
    dispatch(setTypePopup(type))
    dispatch(getPortfolioDetail(item))
    setShowForm(true)
  }
  const columns = useMemo(
    () => [
      {
        Header: PortfolioVN.table.STT,
        accessor: 'extTransId',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <PortfolioNo {...cellProps} page={page} />
        }
      },
      {
        Header: PortfolioVN.table.type,
        accessor: 'type',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <PortfolioType {...cellProps} />
        }
      },
      {
        Header: PortfolioVN.table.name,
        accessor: 'name',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <PortfolioName {...cellProps} />
        }
      },
      {
        Header: PortfolioVN.table.parent,
        accessor: 'treeName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ParentPortfolio {...cellProps} />
        }
      },
      {
        Header: PortfolioVN.table.code,
        accessor: 'requireActivationCode',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <CodePortfolio {...cellProps} />
        }
      },
      {
        Header: PortfolioVN.table.accumulate,
        accessor: 'isAccumulation',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <AccumulatePortfolio {...cellProps} />
        }
      },
      {
        Header: PortfolioVN.table.actived,
        accessor: 'isActivated',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ActivedPortfolio {...cellProps} />
        }
      },
      {
        Header: PortfolioVN.table.action,
        accessor: 'action',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        show: hasPermission,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return (
            <ul className='list-unstyled hstack gap-1 mb-0 '>
              <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                <Link
                  to={`#`}
                  className='btn btn-sm btn-soft-primary'
                  id={`viewtooltip-${cellProps.row.original.id}`}
                  onClick={() => {
                    onClickItem(cellProps.row.original, 'view')
                  }}
                >
                  <i className='mdi mdi-eye-outline' />
                  <UncontrolledTooltip
                    trigger='hover'
                    placement='top'
                    target={`viewtooltip-${cellProps.row.original.id}`}
                  >
                    {'Xem'}
                  </UncontrolledTooltip>
                </Link>
              </li>
              <PermissionWrapper screenCode={screenCode} code={CATEGORY_MANAGEMENT_CODE.PRODUCT_UPDATE_CATEGORY}>
                <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                  <button
                    // disabled={!!cellProps.row.original?.extTransId}
                    className={`btn btn-sm btn-soft-info ${!!cellProps.row.original?.extTransId && 'opacity-50'}`}
                    id={`edittooltip-${cellProps.row.original.id}`}
                    onClick={() => {
                      if (!cellProps.row.original?.extTransId) {
                        onClickItem(cellProps.row.original, 'edit')
                      }
                    }}
                  >
                    <i className='mdi mdi-pencil-outline' />
                    <UncontrolledTooltip
                      trigger='hover'
                      placement='top'
                      target={`edittooltip-${cellProps.row.original.id}`}
                    >
                      {!cellProps.row.original.extTransId ? 'Sửa' : 'Dữ liệu được import từ Bravo không thể sửa'}
                    </UncontrolledTooltip>
                  </button>
                </li>
              </PermissionWrapper>
              <PermissionWrapper screenCode={screenCode} code={CATEGORY_MANAGEMENT_CODE.PRODUCT_DELETE_CATEGORY}>
                <li>
                  <button
                    // disabled={!!cellProps.row.original?.extTransId}
                    className={`btn btn-sm btn-soft-danger ${!!cellProps.row.original?.extTransId && 'opacity-50'}`}
                    onClick={() => {
                      if (!cellProps.row.original?.extTransId) {
                        const item = cellProps.row.original
                        onClickDelete(item)
                      }
                    }}
                    id={`deletetooltip-${cellProps.row.original.id}`}
                  >
                    <i className='mdi mdi-delete-outline' />
                    <UncontrolledTooltip
                      trigger='hover'
                      placement='top'
                      target={`deletetooltip-${cellProps.row.original.id}`}
                    >
                      {!cellProps.row.original.extTransId ? 'Xóa' : 'Dữ liệu được import từ Bravo không thể xóa'}
                    </UncontrolledTooltip>
                  </button>
                </li>
              </PermissionWrapper>
            </ul>
          )
        }
      }
    ],
    [filter, page, portfolios, hasPermission]
  )

  const permissionColumns = useMemo(() => columns.filter((column) => column.show !== false), [columns])

  const setGlobalFilterUser = async (data) => {
    setStorage('filterManagePortfolio', data)
    setFilter({
      ...data,
      page: page
    })
    dispatch(
      getPortfolioList({
        ...data,
        meta: JSON.stringify({
          'Loại danh mục': data?.type_name,
          'Nhập từ khóa tìm kiếm': data?.search
        })
      })
    )
    setPage(1)
  }

  const goToPage = (page) => {
    setPage(page)
    setFilter({ ...filter, page: page })
    dispatch(getPortfolioList({ ...filter, page }))
  }

  const onDeleteAccount = async () => {
    try {
      const res = await deletePortfolioAPI({
        id: selected.id,
        type: selected.type
      })
      if (res) {
        toast('Danh mục sản phẩm được xóa thành công', { type: 'success' })
        setShowDeleteAccount(false)
        dispatch(getPortfolioList(filter))
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const uploadFile = async (files) => {
    setLoadingImport(true)
    let formData = new FormData()
    formData.append('file', files[0])

    try {
      const res = await importFileAPI(url.IMPORT_PORTFOLIO, formData)
      if (res) {
        handleCheckUpload(res.data.data)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
      setLoadingImport(false)
    }
  }

  const handleCheckUpload = async (data) => {
    try {
      const res = await checkUploadFileAPI(data)

      if (!res.data.queueFinish) {
        setTimeout(() => {
          handleCheckUpload(data)
        }, 2000)
      } else {
        if (res.data.countFailed > 0) {
          // handleExportFileError(data)
        }
        toast('Hệ thống đang xử lý. Vui lòng kiểm tra thông tin tại màn hình Lịch sử import', { type: 'success' })
        dispatch(getPortfolioList())
        setLoadingImport(false)
        setShowImport(false)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
      setLoadingImport(false)
    }
  }

  const handleExportFileError = async (data) => {
    try {
      const res = await exportFileError(data)

      if (res) {
        const nameFile = `${moment().format('yyMMDD')}_FileError.xlsx`
        const url = window.URL.createObjectURL(new Blob([res]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', nameFile) //or any other extension
        document.body.appendChild(link)
        link.click()
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
      setShowImport(false)
      setLoadingImport(false)
    }
  }

  const handleClickExport = async () => {
    setLoading(true)
    try {
      const res = await exportPortfolioAPI(filter)
      if (res) {
        const nameFile = `${moment().format('yyMMDD')}_DMSP.xlsx`
        const url = window.URL.createObjectURL(new Blob([res]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', nameFile) //or any other extension
        document.body.appendChild(link)
        link.click()
        toast('Export file thành công!', { type: 'success' })
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
    setLoading(false)
  }

  const handleClickDownloadTemplate = async () => {
    try {
      const res = await downloadTemplateAPI({ type: 'category' })
      const nameFile = `Bieu_mau_import_DMSP.xlsx`
      const url = window.URL.createObjectURL(new Blob([res]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', nameFile) //or any other extension
      document.body.appendChild(link)
      link.click()
      toast('Tải biểu mẫu thành công!', { type: 'success' })
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{PortfolioVN.title}</h4>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody className='border-bottom'>
                    <div className='d-flex align-items-center'>
                      <h5 className='mb-0 card-title flex-grow-1'>{PortfolioVN.list}</h5>
                      <div className='flex-shrink-0'>
                        <PermissionWrapper
                          screenCode={screenCode}
                          code={CATEGORY_MANAGEMENT_CODE.PRODUCT_IMPORT_CATEGORY}
                        >
                          <button onClick={() => setShowImport(true)} className='btn btn-primary me-2 pe-4'>
                            <i className='mdi mdi-database-import me-1 ms-2' />
                            {PortfolioVN.import}
                          </button>
                        </PermissionWrapper>

                        {/* <button onClick={() => handleClickExport()} className="btn btn-primary me-2 pe-4">
                                                    <i className="mdi mdi-database-export me-1 ms-2" />
                                                    {PortfolioVN.export}
                                                </button> */}
                        <PermissionWrapper
                          screenCode={screenCode}
                          code={CATEGORY_MANAGEMENT_CODE.PRODUCT_CREATE_CATEGORY}
                        >
                          <button
                            onClick={() => {
                              setShowForm(true)
                              dispatch(setTypePopup('create'))
                            }}
                            className='btn btn-primary me-2 pe-4'
                          >
                            <i className='mdi mdi-plus me-1' />
                            {PortfolioVN.add}
                          </button>
                          <button
                            onClick={() => {
                              navigate('/manage-import-history')
                            }}
                            className='btn btn-primary'
                          >
                            <i className='mdi mdi-history me-1' />
                            {'Lịch sử import'}
                          </button>
                        </PermissionWrapper>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <TableContainer
                      columns={permissionColumns}
                      data={portfolios}
                      isGlobalFilter={true}
                      setGlobalFilterUser={setGlobalFilterUser}
                      isPortfolioGlobalFilter={true}
                      // isSearchInput={false}
                      placeholderSearch={PortfolioVN.filter.placeholderSearch}
                      isPagination={true}
                      iscustomPageSizeOptions={false}
                      isShowingPageLength={false}
                      customPageSize={10}
                      tableClass='table-bordered align-middle nowrap mt-2'
                      paginationDiv='col-sm-12 col-md-7'
                      pagination='pagination justify-content-end pagination-rounded'
                      useAPIChangePaging
                      currentPage={page}
                      gotoPageCustom={goToPage}
                      total={total}
                      isSearchInput={false}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ConfirmModal
        show={showDeleteAccount}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-alert'></i>
          </div>
        }
        onConfirmClick={onDeleteAccount}
        onCloseClick={() => setShowDeleteAccount(false)}
        title='Xóa danh mục'
        description={`Bạn có chắc chắn muốn xóa danh mục?`}
      />
      <AddEditPortfolio
        onCloseClick={() => {
          setShowForm(false)
        }}
        selected={selected}
        show={showForm}
        setPage={setPage}
      />
      {showImport && (
        <ImportModal
          onCloseClick={() => {
            setShowImport(false)
          }}
          show={showImport}
          onConfirmClick={uploadFile}
          loading={isLoadingImport}
          title={PortfolioVN.importModal.title}
          onDownloadClick={handleClickDownloadTemplate}
        />
      )}
    </React.Fragment>
  )
}

export default ManagePortfolio
