import React, { useEffect, useState } from 'react'
import { Col, Label } from 'reactstrap'

//flatpickr
import { MAX_LENGTH_SEARCH } from 'constants/constants'
import { ProductVN } from 'constants/vn'
import 'flatpickr/dist/themes/material_blue.css'
import { getChannelAPI, getPortfolioAllAPI } from 'helpers/backend_helper'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { FormInput } from './form-input/FormInput'
import { FormSelect } from './form-select/FormSelect'
import { getStorage } from 'helpers/global_helper'

const ProductFilter = ({ setGlobalFilter, search, setSearch }) => {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    zone: app.zone,
    subzone: app.subzone,
    province: app.province,
    district: app.district,
    ward: app.ward,
    myLocationManagement: app.myLocationManagement
  }))
  const { zone, subzone, province, district, ward, myLocationManagement } = useSelector(LayoutProperties)

  const [filter, setFilter] = useState({})

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }

  const [industry, setIndustry] = useState()
  const [industryOptions, setIndustryOptions] = useState()
  const [industrySector, setIndustrysector] = useState()
  const [category, setCategory] = useState()
  const [model, setModel] = useState()
  const [noRewardOptions, setNoRewardOptions] = useState([
    // { value: 'all', label: 'Tất cả' },
    { value: 'is_noreward_accumulation', label: 'Chặn thưởng tích luỹ' },
    { value: 'is_noreward_activation', label: 'Chặn thưởng kích hoạt' },
    { value: 'is_noreward_display', label: 'Chặn thưởng trưng bày' }
  ])
  const [channel, setChannel] = useState([])

  const lstDistrict = myLocationManagement

  ////Lấy danh sách ward
  // Get all unique parentId values from lstDistrict
  const wardParentIds = myLocationManagement?.map((district) => district.id)

  //Từ wardParentIds, lấy ra những ward có id trong wardParentIds
  const lstWard = ward.filter((ward) => wardParentIds.includes(ward.parentId))

  ////Lấy danh sách province
  // Get all unique parentId values from lstDistrict
  const districtParentIds = lstDistrict?.map((district) => district.parentId)
  //Từ districtParentIds, lấy ra những district có id trong districtParentIds
  const lstProvionce = province.filter((province) => districtParentIds.includes(province.id))

  ////Lấy danh sách subZone
  // Get all unique subZoneId(parentId) values from lstProvionce
  const provinceParentIds = lstProvionce?.map((province) => province.subZoneId)
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstSubZone = subzone?.filter((subZone) => provinceParentIds.includes(subZone.id))

  ////Lấy danh sách zone
  // Get all unique zoneId(parentId) values from lstProvionce
  const subZoneParentIds = lstProvionce?.map((province) => province.zoneId) // trong lstProvionce có lưu zoneId
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstZone = zone.filter((zone) => subZoneParentIds.includes(zone.id))

  const getDataOptions = async () => {
    try {
      const resIndustry = await getPortfolioAllAPI({ type: 'industry' })
      const resIndustrysector = await getPortfolioAllAPI({ type: 'industrySector' })
      const resCategory = await getPortfolioAllAPI({ type: 'category' })
      const resModel = await getPortfolioAllAPI({ type: 'model' })
      const resChannel = await getChannelAPI()
      setChannel(resChannel?.data)
      setIndustry(resIndustry?.data)
      if (resIndustry?.data?.length > 0) {
        setIndustryOptions(
          [{ label: 'Tất cả', value: 'all' }].concat(
            resIndustry?.data?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )
        )
      } else {
        setIndustryOptions([])
      }

      setIndustrysector(resIndustrysector?.data)
      setCategory(resCategory?.data)
      setModel(resModel?.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const filterMangeProduct = getStorage('filterManageProduct')
    setFilter(filterMangeProduct)
    getDataOptions()
  }, [])

  return (
    <React.Fragment>
      <Col xxl={6} lg={6} xs={6} className='mt-2'>
        <Label className='form-label'>Ngành</Label>

        <FormSelect
          options={industryOptions}
          onChange={(value, label) => {
            handleSelect(value, 'industry_ids')
            handleSelect(
              label.map((e) => e.label),
              'industry_names'
            )
            handleSelect([], 'industry_sector_ids')
            handleSelect([], 'category_ids')
            handleSelect([], 'model_ids')
            handleSelect([], 'industry_sector_names')
            handleSelect([], 'category_names')
            handleSelect([], 'model_names')
          }}
          onSelect={(value) => {
            const all = industry?.map((item) => item.id)
            const all_names = industry?.map((item) => item.name)
            if (value && value === 'all') {
              handleSelect([...all], 'industry_ids')
              handleSelect([...all_names], 'industry_names')
            }
          }}
          value={filter?.industry_ids}
          placeholder='Chọn Ngành'
          mode='multiple'
        />
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-2'>
        <Label className='form-label'>{'Ngành hàng'}</Label>
        <FormSelect
          options={
            industrySector?.filter((e) => filter?.industry_ids?.includes(e.parentId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  industrySector
                    ?.filter((e) => filter?.industry_ids?.includes(e.parentId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter?.industry_sector_ids}
          onChange={(value, label) => {
            handleSelect(value, 'industry_sector_ids')
            handleSelect(
              label.map((e) => e.label),
              'industry_sector_names'
            )
            handleSelect([], 'category_ids')
            handleSelect([], 'model_ids')
            handleSelect([], 'category_names')
            handleSelect([], 'model_names')
          }}
          onSelect={(value) => {
            const all = industrySector
              ?.filter((e) => filter?.industry_ids?.includes(e.parentId))
              ?.map((item) => item.id)
            const all_names = industrySector
              ?.filter((e) => filter?.industry_ids?.includes(e.parentId))
              ?.map((item) => item.name)
            if (value && value === 'all') {
              handleSelect([...all], 'industry_sector_ids')
              handleSelect([...all_names], 'industry_sector_names')
            }
          }}
          placeholder={ProductVN.filter?.industrySector.placeholder}
          mode='multiple'
        />
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>{ProductVN.filter?.category.label}</Label>
        <FormSelect
          options={
            category?.filter((e) => filter?.industry_sector_ids?.includes(e?.parentId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  category
                    ?.filter((e) => filter?.industry_sector_ids?.includes(e?.parentId))
                    .map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter?.category_ids}
          onChange={(value, label) => {
            handleSelect(value, 'category_ids')
            handleSelect(
              label.map((e) => e.label),
              'category_names'
            )

            handleSelect([], 'model_ids')
          }}
          onSelect={(value) => {
            const all = category
              ?.filter((e) => filter?.industry_sector_ids?.includes(e?.parentId))
              ?.map((item) => item.id)
            const all_names = category
              ?.filter((e) => filter?.industry_sector_ids?.includes(e?.parentId))
              ?.map((item) => item.name)
            if (value && value === 'all') {
              handleSelect([...all], 'category_ids')
              handleSelect([...all_names], 'category_names')
            }
          }}
          placeholder={ProductVN.filter?.category.placeholder}
          mode='multiple'
        />
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>Nhập Model</Label>
        <FormSelect
          placeholder='Nhập Model'
          options={
            model?.filter((e) => filter?.category_ids?.includes(e.parentId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  model
                    ?.filter((e) => filter?.category_ids?.includes(e.parentId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          onChange={(value, label) => {
            handleSelect(value, 'model_ids')
            handleSelect(
              label.map((e) => e.label),
              'model_names'
            )
          }}
          onSelect={(value) => {
            const all = model?.filter((e) => filter?.category_ids?.includes(e.parentId))?.map((item) => item.id)
            const all_names = model?.filter((e) => filter?.category_ids?.includes(e.parentId))?.map((item) => item.name)

            if (value && value === 'all') {
              handleSelect([...all], 'model_ids')
              handleSelect([...all_names], 'model_names')
            }
          }}
          value={filter?.model_ids}
          mode='multiple'
        />
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>Nhập mã Serial</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập Serial '
          value={filter?.search || ''}
          name='search'
          id={`input-filter`}
          onChange={(e) => handleSelect(e.target.value, 'search')}
          onBlur={(e) => handleSelect(e.target.value, 'search')}
        />
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>Miền</Label>
        <FormSelect
          options={[{ label: 'Tất cả', value: 'all' }].concat(
            lstZone?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )}
          value={filter?.zone_ids}
          onChange={(value, label) => {
            handleSelect(value, 'zone_ids')
            handleSelect(
              label.map((e) => e.label),
              'zone_names'
            )

            handleSelect([], 'sub_zone_ids')
            handleSelect([], 'province_ids')
            handleSelect([], 'district_ids')
            handleSelect([], 'ward_ids')
          }}
          onSelect={(value) => {
            const all = lstZone?.map((item) => item.id)
            const all_names = lstZone?.map((item) => item.id)

            if (value && value === 'all') {
              handleSelect([...all], 'zone_ids')
              handleSelect([...all_names], 'zone_names')
            }
          }}
          mode='multiple'
          placeholder={ProductVN.filter?.zone.placeholder}
        />
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>{ProductVN.filter?.status.label}</Label>
        <FormSelect
          options={[
            {
              label: 'Đã kích hoạt',
              value: 'activated'
            },
            {
              label: 'Đã tích luỹ',
              value: 'accumulated'
            },
            {
              label: 'Chưa tích luỹ',
              value: 'active'
            }
          ]}
          value={filter?.status}
          onChange={(value, label) => {
            handleSelect(value, 'status')
            handleSelect(label?.label, 'status_name')
          }}
          placeholder={ProductVN.filter?.status.placeholder}
        />
      </Col>

      {/* <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>Chặn thưởng</Label>

        <FormSelect
          options={[{ label: 'Tất cả', value: 'all' }].concat(noRewardOptions)}
          onChange={(value, label) => {
            handleSelect(value, 'noRewards')
            handleSelect(
              label.map((e) => e.label),
              'noReward_Names'
            )
          }}
          onSelect={(value) => {
            const all = noRewardOptions?.map((item) => item.value)
            const all_names = noRewardOptions?.map((item) => item.label)
            if (value && value === 'all') {
              handleSelect([...all], 'noRewards')
              handleSelect([...all_names], 'noReward_Names')
            }
          }}
          value={filter?.noRewards}
          placeholder='Chọn chặn thưởng'
          mode='multiple'
        />
      </Col> */}

      <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>Kênh kinh doanh</Label>

        <FormSelect
          options={[{ label: 'Tất cả', value: 'all' }].concat(channel?.map((e) => ({ label: e.name, value: e.id })))}
          onChange={(value, label) => {
            handleSelect(value, 'channel_ids')
            handleSelect(
              label.map((e) => e.label),
              'channel_names'
            )
          }}
          onSelect={(value) => {
            const all = channel?.map((item) => item.id)
            const all_names = channel?.map((item) => item.name)
            if (value && value === 'all') {
              handleSelect([...all], 'channel_ids')
              handleSelect([...all_names], 'channel_names')
            }
          }}
          value={filter?.channel_ids}
          placeholder='Chọn kênh kinh doanh'
          mode='multiple'
        />
      </Col>

      <Col xxl={4} lg={6} xs={6} className='d-flex align-items-end justify-content mt-5'>
        <div className='mb-xxl-0 me-2'>
          <button
            type='button'
            className='btn btn-soft-danger w-100'
            onClick={() => {
              setFilter({
                industry_ids: undefined,
                industry_sector_ids: undefined,
                model_ids: undefined,
                category_ids: undefined,
                zone_ids: undefined,
                status: undefined,
                industry_names: undefined,
                industry_sector_names: undefined,
                model_names: undefined,
                category_names: undefined,
                zone_names: undefined,
                status_name: undefined,
                noRewards: undefined,
                search: ''
              })
              setGlobalFilter({})
            }}
          >
            Xóa tìm kiếm
          </button>
        </div>
        <div className='mb-xxl-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={() => {
              const _filter = !filter
                ? filter
                : Object.fromEntries(
                    Object.entries(filter).filter(([key, value]) => value !== undefined && value !== '')
                  )
              setGlobalFilter({ ..._filter })
            }}
          >
            Tìm kiếm
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default ProductFilter
