import React, { useEffect, useState } from 'react'
import { Col, Label, UncontrolledTooltip } from 'reactstrap'

//flatpickr
import 'flatpickr/dist/themes/material_blue.css'
import { PortfolioVN, ManageShopVN, ProductVN } from 'constants/vn'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { TypePortfolioOptions } from 'constants/constants'
import { FormSelect } from './form-select/FormSelect'
import { FormInput } from './form-input/FormInput'
import { MAX_LENGTH_SEARCH } from 'constants/constants'
import { getPortfolioAllAPI } from 'helpers/backend_helper'
import { getStorage } from 'helpers/global_helper'

const PortfolioGlobalFilter = ({ setGlobalFilter, search, setSearch }) => {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    province: app.province,
    district: app.district,
    ward: app.ward
  }))
  const { province } = useSelector(LayoutProperties)
  const [industry, setIndustry] = useState()
  const [industryOptions, setIndustryOptions] = useState()
  const [industrySector, setIndustrysector] = useState()
  const [category, setCategory] = useState()
  const [model, setModel] = useState()

  const [filter, setFilter] = useState({})

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }

  const onFilterChange = (name, value) =>
    setFilter((prev) => ({
      ...prev,
      [name]: value
    }))

  useEffect(() => {
    getIndustryDataOptions()
    const filterMangeShop = getStorage('filterManagePortfolio')
    setFilter(filterMangeShop)
  }, [])

  const getIndustryDataOptions = async () => {
    try {
      const resIndustry = await getPortfolioAllAPI({ type: 'industry' })
      const resIndustrysector = await getPortfolioAllAPI({ type: 'industrySector' })
      const resCategory = await getPortfolioAllAPI({ type: 'category' })
      const resModel = await getPortfolioAllAPI({ type: 'model' })
      setIndustry(resIndustry?.data)
      if (resIndustry?.data?.length > 0) {
        setIndustryOptions(
          [{ label: 'Tất cả', value: 'all' }].concat(
            resIndustry?.data?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )
        )
      } else {
        setIndustryOptions([])
      }

      setIndustrysector(resIndustrysector?.data)
      setCategory(resCategory?.data)
      setModel(resModel?.data)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <React.Fragment>
      <Col xxl={6} lg={6} xs={6} className='mt-2'>
        <Label className='form-label'>Tên danh mục</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập tên danh mục'
          value={filter?.search || ''}
          name='shop-name'
          id={`input-shop-name-filter`}
          onChange={(e) => onFilterChange('search', e.target.value)}
          onBlur={(e) => onFilterChange('search', e.target.value)}
        />
        <UncontrolledTooltip trigger='hover' placement='top' target={`input-shop-name-filter`}>
          {''}
        </UncontrolledTooltip>
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-2'>
        <Label className='form-label'>Ngành</Label>
        <FormSelect
          options={industryOptions}
          onChange={(value, label) => {
            onFilterChange('industry_ids', value)
            onFilterChange(
              'industry_names',
              label.map((e) => e.label)
            )

            onFilterChange('industry_sector_ids', [])
            onFilterChange('category_ids', [])
            onFilterChange('model_ids', [])
            onFilterChange('industry_sector_names', [])
            onFilterChange('category_names', [])
            onFilterChange('model_names', [])
          }}
          onSelect={(value) => {
            const all = industry?.map((item) => item.id)
            const all_names = industry?.map((item) => item.name)
            if (value && value === 'all') {
              onFilterChange('industry_ids', [...all])
              onFilterChange('industry_names', [...all_names])
            }
          }}
          value={filter?.industry_ids}
          placeholder='Chọn Ngành'
          mode='multiple'
        />
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-2'>
        <Label className='form-label'>Ngành hàng</Label>
        <FormSelect
          options={
            industrySector?.filter((e) => filter?.industry_ids?.includes(e.parentId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  industrySector
                    ?.filter((e) => filter?.industry_ids?.includes(e.parentId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          onChange={(value, label) => {
            onFilterChange('industry_sector_ids', value)
            onFilterChange(
              'industry_sector_names',
              label.map((e) => e.label)
            )

            onFilterChange('category_ids', [])
            onFilterChange('model_ids', [])
            onFilterChange('category_names', [])
            onFilterChange('model_names', [])
          }}
          onSelect={(value) => {
            const all = industrySector
              ?.filter((e) => filter?.industry_ids?.includes(e.parentId))
              ?.map((item) => item.id)
            const all_names = industrySector
              ?.filter((e) => filter?.industry_ids?.includes(e.parentId))
              ?.map((item) => item.name)

            if (value && value === 'all') {
              onFilterChange('industry_sector_ids', [...all])
              onFilterChange('industry_sector_names', [...all_names])
            }
          }}
          value={filter?.industry_sector_ids}
          placeholder='Chọn Ngành hàng'
          mode='multiple'
        />
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-2'>
        <Label className='form-label'>Nhóm sản phẩm</Label>
        <FormSelect
          options={
            category?.filter((e) => filter?.industry_sector_ids?.includes(e?.parentId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  category
                    ?.filter((e) => filter?.industry_sector_ids?.includes(e?.parentId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          onChange={(value, label) => {
            onFilterChange('category_ids', value)
            onFilterChange(
              'category_names',
              label.map((e) => e.label)
            )

            onFilterChange('model_ids', [])
            onFilterChange('model_names', [])
          }}
          onSelect={(value) => {
            const all = category
              ?.filter((e) => filter?.industry_sector_ids?.includes(e?.parentId))
              ?.map((item) => item.id)
            const all_names = category
              ?.filter((e) => filter?.industry_sector_ids?.includes(e?.parentId))
              ?.map((item) => item.name)
            if (value && value === 'all') {
              onFilterChange('category_ids', [...all])
              onFilterChange('category_names', [...all_names])
            }
          }}
          value={filter?.category_ids}
          placeholder='Chọn nhóm sản phẩm'
          mode='multiple'
        />
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-2'>
        <Label className='form-label'>Nhập Model</Label>

        <FormSelect
          placeholder={'Nhập Model'}
          options={
            model?.filter((e) => filter?.category_ids?.includes(e.parentId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  model
                    ?.filter((e) => filter?.category_ids?.includes(e.parentId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          onChange={(value, label) => {
            onFilterChange('model_ids', value)
            onFilterChange(
              'model_names',
              label.map((e) => e.label)
            )
          }}
          onSelect={(value) => {
            const all = model?.filter((e) => filter?.category_ids?.includes(e.parentId))?.map((item) => item.id)
            const all_names = model?.filter((e) => filter?.category_ids?.includes(e.parentId))?.map((item) => item.name)

            if (value && value === 'all') {
              onFilterChange('model_ids', [...all])
              onFilterChange('model_names', [...all_names])
            }
          }}
          value={filter?.model_ids}
          mode='multiple'
        />
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-2'>
        <Label className='form-label'>{PortfolioVN.filter?.typePortfolio.label}</Label>
        <FormSelect
          name='type'
          options={TypePortfolioOptions?.map((item) => ({
            value: item.value,
            label: item.label
          }))}
          value={filter?.type}
          onChange={(value, label) => {
            handleSelect(value, 'type')
            handleSelect(label?.label, 'type_name')
          }}
          placeholder='Tất cả'
        />
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-2'>
        <Label className='form-label'>{'Tích luỹ'}</Label>
        <FormSelect
          options={[
            {
              label: 'Tất cả',
              value: 'undefined'
            },
            {
              label: 'Có',
              value: 'true'
            },
            {
              label: 'Không',
              value: 'false'
            }
          ]}
          value={filter?.accumulation}
          onChange={(value, label) => {
            handleSelect(value, 'accumulation')
            handleSelect(label?.label, 'accumulation_name')
          }}
          placeholder={'Chọn tích luỹ'}
        />
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-2'>
        <Label className='form-label'>{'Kích hoạt'}</Label>
        <FormSelect
          options={[
            {
              label: 'Tất cả',
              value: 'undefined'
            },
            {
              label: 'Có',
              value: 'true'
            },
            {
              label: 'Không',
              value: 'false'
            }
          ]}
          value={filter?.activated}
          onChange={(value, label) => {
            handleSelect(value, 'activated')
            handleSelect(label?.label, 'activated_name')
          }}
          placeholder={'Chọn kích hoạt'}
        />
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-2'>
        <Label className='form-label'>{'Mã khách hàng'}</Label>
        <FormSelect
          options={[
            {
              label: 'Tất cả',
              value: 'undefined'
            },
            {
              label: 'Có',
              value: 'true'
            },
            {
              label: 'Không',
              value: 'false'
            }
          ]}
          value={filter?.require_activation_code}
          onChange={(value, label) => {
            handleSelect(value, 'require_activation_code')
            handleSelect(label?.label, 'require_activation_code_name')
          }}
          placeholder={'Chọn mã khách hàng'}
        />
      </Col>

      <Col xxl={8} lg={10} className='d-flex align-items-end justify-content-end mt-3 '>
        <div className='mb-xxl-0 me-2'>
          <button
            type='button'
            className='btn btn-soft-danger w-100'
            onClick={() => {
              setFilter({
                type: undefined,
                type_name: undefined
              })
              setSearch('')
              setGlobalFilter({})
            }}
          >
            Xóa tìm kiếm
          </button>
        </div>
        <div className='mb-xxl-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={() => {
              const _filter = !filter
                ? filter
                : Object.fromEntries(Object.entries(filter).filter(([key, value]) => value !== undefined))
              setGlobalFilter({ ..._filter, search })
            }}
          >
            Tìm kiếm
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default PortfolioGlobalFilter
