import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { Col, Row, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import { ActivationVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import {
  checkExportFileAPI,
  deleteActivationAPI,
  exportActivationAPI,
  exportActivationFetcherAPI
} from 'helpers/backend_helper'
import {
  ActivationIndustrySector,
  ActivationCategory,
  ActivationModel,
  Serial,
  StatusActivation,
  Shop,
  ActivatedDate,
  ActivationCode,
  AccountType,
  PICName,
  Customer,
  Model
} from './ManageActivationCol'
import AddEditActivation from './components/AddEditActivation'
import { getActivationDetail, getActivationList, setFilter, setTypePopupActivation } from 'store/actions'
import moment from 'moment'
import { Space } from 'antd'
import { TableRowSpan } from 'components/Common/TableCustom'
import ManageActivationFilter from './components/ManageActivationFilter'
import { LongLatToDMS, Zone } from 'pages/ManageAccumulation/ManageAccumulationCol'

function ManageActivation() {
  //meta title
  document.title = 'Activation List | HPG'

  const dispatch = useDispatch()

  const selectActivationsState = (state) => state.ActivationReducer
  const ActivationsProperties = createSelector(selectActivationsState, (reducer) => ({
    activations: reducer.activations,
    loading: reducer.loading,
    total: reducer.total
  }))

  const selectProfileState = (state) => state.Profile
  const ProfileProperties = createSelector(selectProfileState, (profile) => ({
    profile: profile.profile
  }))
  const { profile } = useSelector(ProfileProperties)

  const { activations, loading, total } = useSelector(ActivationsProperties)

  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (layout) => ({
    filter: layout.filter
  }))
  const { filter } = useSelector(LayoutProperties)

  const [isLoading, setLoading] = useState(loading)
  const [showDeleteAccount, setShowDeleteAccount] = useState(false)
  const [selected, setSelected] = useState()
  const [showForm, setShowForm] = useState(false)
  const [page, setPage] = useState(1)
  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: 10,
    totalRows: total
  })

  useEffect(() => {
    dispatch(setFilter({ page: 1 }))
    dispatch(getActivationList({ page: 1 }))
  }, [dispatch])

  const onClickDelete = (item) => {
    setSelected(item)
    setShowDeleteAccount(true)
  }

  const onClickItem = async (item, type) => {
    setSelected(item)
    dispatch(setTypePopupActivation(type))
    dispatch(getActivationDetail(item))
    setShowForm(true)
  }

  const columns = useMemo(
    () => [
      {
        Header: ActivationVN.table.PICName,
        // accessor: 'usersShopName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <PICName {...cellProps} />
        }
      },
      {
        Header: ActivationVN.table.customer,
        accessor: 'customerName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Customer {...cellProps} />
        }
      },
      {
        Header: ActivationVN.table.accountType,
        accessor: 'usersClient',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <AccountType {...cellProps} />
        }
      },
      // {
      //   Header: 'Miền',
      //   accessor: 'zoneName',
      //   filterable: true,
      //   disableSortBy: true,
      //   hideSort: true,
      //   Cell: (cellProps) => {
      //     return <Zone {...cellProps} />
      //   }
      // },
      // {
      //   Header: 'Vùng',
      //   accessor: 'subZoneName',
      //   filterable: true,
      //   disableSortBy: true,
      //   hideSort: true,
      //   Cell: (cellProps) => {
      //     return <Zone {...cellProps} />
      //   }
      // },
      // {
      //   Header: 'Tình/Thành phố',
      //   accessor: 'provinceName',
      //   filterable: true,
      //   disableSortBy: true,
      //   hideSort: true,
      //   Cell: (cellProps) => {
      //     return <Zone {...cellProps} />
      //   }
      // },
      // {
      //   Header: 'Quận/ Huyện',
      //   accessor: 'districtName',
      //   filterable: true,
      //   disableSortBy: true,
      //   hideSort: true,
      //   Cell: (cellProps) => {
      //     return <Zone {...cellProps} />
      //   }
      // },
      // {
      //   Header: 'Phường xã',
      //   accessor: 'wardName',
      //   filterable: true,
      //   disableSortBy: true,
      //   hideSort: true,
      //   Cell: (cellProps) => {
      //     return <Zone {...cellProps} />
      //   }
      // },
      {
        Header: 'Ngành',
        accessor: 'industryName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Zone {...cellProps} />
        }
      },
      {
        Header: 'Ngành hàng',
        accessor: 'industrySectorName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Zone {...cellProps} />
        }
      },
      {
        Header: ActivationVN.table.category,
        accessor: 'categoryName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ActivationCategory {...cellProps} />
        }
      },
      {
        Header: ActivationVN.table.model,
        // accessor: 'modelName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Model {...cellProps} />
        }
      },
      {
        Header: ActivationVN.table.serial,
        // accessor: 'productSerial',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Serial {...cellProps} />
        }
      },
      {
        Header: ActivationVN.table.activationCode,
        accessor: 'productActivationCode',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ActivationCode {...cellProps} />
        }
      },
      {
        Header: ActivationVN.table.activatedDate,
        accessor: 'userRewardCreatedAt',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ActivatedDate {...cellProps} />
        }
      },
      {
        Header: 'Vị trí',
        accessor: (cell) => {
          return cell
        },
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <LongLatToDMS {...cellProps} />
        }
      },
      {
        Header: ActivationVN.table.status,
        accessor: 'status',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <StatusActivation {...cellProps} />
        }
      },
      {
        Header: 'Tổng',
        accessor: 'countUserReward',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Zone {...cellProps} />
        }
      },
      {
        Header: ActivationVN.table.action,
        accessor: 'action',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return (
            <ul className='list-unstyled hstack gap-1 mb-0'>
              <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                <Link
                  to={`#`}
                  className='btn btn-sm btn-soft-primary'
                  id={`viewtooltip-${cellProps.row.original.productId}`}
                  onClick={() => {
                    onClickItem(cellProps.row.original, 'view')
                  }}
                >
                  <i className='mdi mdi-eye-outline' />
                  <UncontrolledTooltip
                    trigger='hover'
                    placement='top'
                    target={`viewtooltip-${cellProps.row.original.productId}`}
                  >
                    Xem
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li>
                <Link
                  to='#'
                  className='btn btn-sm btn-soft-danger'
                  onClick={() => {
                    onClickDelete(cellProps.row.original)
                  }}
                  id={`deletetooltip-${cellProps.row.original.id}`}
                >
                  <i className='mdi mdi-delete-outline' />
                  <UncontrolledTooltip
                    trigger='hover'
                    placement='top'
                    target={`deletetooltip-${cellProps.row.original.id}`}
                  >
                    Xóa
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        }
      }
    ],
    [filter]
  )

  const setGlobalFilterUser = async (data) => {
    if (data.start_date1 !== undefined && data.end_date1 !== undefined) {
      data.start_date = data.start_date1.startOf('day').toDate()
      data.end_date = data.end_date1.endOf('day').toDate()
    } else {
      data.start_date = undefined
      data.end_date = undefined
    }

    dispatch(
      getActivationList({
        ...data,
        meta: {
          Ngành: data?.industry_names,
          'Ngành hàng': data?.industry_sector_names,
          'Nhóm sản phẩm': data?.category_names,
          'Nhập Model': data?.model_names,
          'Nhập Serial': data?.serial,
          'Nhập tên cửa hàng': data?.shop_name,
          'Số điện thoại': data?.phone,
          'Nhập tên khách hàng': data?.customer_name,
          'Số điện thoại khách hàng': data?.customer_phone,
          'Loại tài khoản': data?.client_namme,
          Miền: data?.zone_names,
          Vùng: data?.sub_zone_names,
          'Tỉnh/ Thành phố': data?.province_names,
          'Quận/ Huyện': data?.district_names,
          'Phường xã': data?.ward_names,
          'Trạng thái': data?.status_name,
          'Tính tổng': data?.group_by_name,
          'Thời gian kích hoạt':
            data?.start_date1?.startOf('day').toDate() + ' - ' + data?.end_date1?.endOf('day').toDate()
        }
      })
    )
    dispatch(setFilter({ ...data, page: 1 }))
    setPage(1)
  }

  const goToPage = (page) => {
    dispatch(getActivationList({ ...filter, page }))
    setPage(page)
  }

  const onDeleteAccount = async () => {
    try {
      const res = await deleteActivationAPI({
        id: selected.userRewardId
      })
      if (res) {
        toast(res.message, { type: 'success' })
        setShowDeleteAccount(false)
        dispatch(getActivationList({ ...filter, page: page }))
        setPage(page)
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const handleClickExport = async () => {
    // setLoading(true)
    // try {
    //   const res = await exportActivationAPI(filter)
    //   if (res?.data?.success === false) {
    //     toast(res?.message, { type: 'error' })
    //   } else {
    //     toast('Hệ thống đang xử lý. Tệp của bạn đã được gửi tới email ' + profile?.email + ' trong vài phút', {
    //       type: 'success'
    //     })
    //   }
    // } catch (error) {
    //   toast(error.response.data.message, { type: 'error' })
    // }
    // setLoading(false)

    setLoading(true)
    try {
      const response = await exportActivationFetcherAPI({ ...filter })
      const data = response.headers.get('Content-Type')
      if (data !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        toast('Hệ thống đang xử lý. Tệp của bạn đã được gửi tới email ' + profile?.email + ' trong vài phút', {
          type: 'success'
        })
      } else {
        try {
          const blob = await response.blob() // Fetch the blob
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${moment().format('yyMMDD')}_Quan ly kich hoat.xlsx`) // Tên file
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          toast('Export file thành công!', { type: 'success' })
        } catch (err) {
          console.log('err', err)
        }
      }
    } catch (error) {}
    setLoading(false)
  }

  const handleResetFilter = () => {
    dispatch(setFilter({ page: 1 }))
    dispatch(getActivationList({ page: 1 }))
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{ActivationVN.title}</h4>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody className='border-bottom'>
                    <div className='d-flex align-items-center'>
                      <h5 className='mb-0 card-title flex-grow-1'>{ActivationVN.list}</h5>
                      <div className='flex-shrink-0'>
                        <button onClick={() => handleClickExport()} className='btn btn-primary me-2 pe-4'>
                          <i className='mdi mdi-database-export me-1 ms-2' />
                          {ActivationVN.export}
                        </button>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <Space size='middle' direction='vertical' className='w-100'>
                      <ManageActivationFilter
                        handleFilter={setGlobalFilterUser}
                        handleResetFilter={handleResetFilter}
                      />
                      <TableRowSpan
                        columns={columns}
                        data={activations}
                        pagination={pagination}
                        setPagination={setPagination}
                        gotoPageCustom={goToPage}
                        currentPage={page}
                        total={total}
                        isShowTotal={true}
                      />
                    </Space>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ConfirmModal
        show={showDeleteAccount}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-alert'></i>
          </div>
        }
        onConfirmClick={onDeleteAccount}
        onCloseClick={() => setShowDeleteAccount(false)}
        title='Xóa bản ghi'
        description={`Các kết quả thưởng của bản ghi này và bản kích hoạt liên quan (nếu có) sẽ đồng thời bị hủy.\n Bạn có chắc chắn muốn xoá bản ghi kích hoạt?`}
      />
      <AddEditActivation
        onCloseClick={() => {
          setShowForm(false)
        }}
        show={showForm}
      />
    </React.Fragment>
  )
}

export default ManageActivation
