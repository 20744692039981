import React, { useEffect, useMemo, useState } from 'react'

//import components

import {
  Type,
  ListStartEndDate,
  ListProgramingApply,
  ModelName,
  SumBonus,
  CreateDurationMoment,
  Serial,
  UserShopName,
  ProgramCode,
  ProgramReward,
  GrString
} from './components/ManageAccountClass/ManageAccountCol'

import { Col, Row, UncontrolledTooltip, Card, CardBody, Label } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import { ClassConfigVN, LocalityVN, ManageProgramVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import {
  checkExportFileAPI,
  exportByProductAPI,
  exportByProductFetcherAPI,
  getPointConfigListAPI,
  getProductBonusTempListAPI,
  setPriorityReconcileProductAPI,
  updatePointConfigAPI
} from 'helpers/backend_helper'
import { Form, Input, Space } from 'antd'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { StatusProgramOptions, TypeProgramOptions } from 'constants/constants'
import { formateDate } from 'helpers/global_helper'
import fileDownload from 'js-file-download'
import moment from 'moment'
import { TableRowSpan } from 'components/Common/TableCustom'
import ProductBonusTempListFilter from './components/ProductBonusTempListFilter'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'

function ProductBonusTempList() {
  //meta title
  document.title = 'Manage Point Config CMS List | Skote - React Admin & Dashboard Template'

  const [isLoading, setLoading] = useState(false)
  const [filter, setFilter] = useState({})
  const [edit, setEdit] = useState(true)
  const [listTable, setListTable] = useState([])
  const [total, setTotal] = useState()
  const [listProvinces, setListProvinces] = useState([])

  const [form] = Form.useForm()
  const watchForm = Form.useWatch([], form)
  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const [page, setPage] = useState(1)
  const [listDataChange, setListDataChange] = useState([])
  const [flatData, setFlatData] = useState([])
  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: 10,
    totalRows: total
  })

  const selectProfileState = (state) => state.Profile
  const ProfileProperties = createSelector(selectProfileState, (profile) => ({
    profile: profile.profile
  }))
  const { profile } = useSelector(ProfileProperties)

  useEffect(() => {
    handleGetListTable()
  }, [filter])

  useEffect(() => {
    if (edit == true) {
      listTable?.map((item, index) => {
        form.setFieldsValue({
          [item.id]: item.point
        })
      })
    }
  }, [edit])

  useEffect(() => {
    if (edit == true) {
      listTable?.map((item, index) => {
        form.setFieldsValue({
          [item.id]: item.point
        })
      })
    }
    listDataChange?.map((item, index) => {
      form.setFieldsValue({
        [item.id]: item.point
      })

      handleCheckPoint(item.id, item.point)
    })
  }, [listTable])

  useEffect(() => {
    const data = form.getFieldsError()
    const check = []
    data?.map((item) => {
      if (item.errors.length > 0) {
        check.push(false)
      } else {
        check.push(true)
      }
    })

    if (check.includes(false)) {
      setDisabledSubmit(true)
    } else {
      setDisabledSubmit(false)
    }
  }, [watchForm])

  useEffect(() => {
    const flatDatas = []
    if (listTable.length > 0) {
      listTable?.forEach((item) => {
        item?.programming?.forEach((program, index) => {
          flatDatas.push({
            id: item?.id,
            accumulationTime: item?.accumulationTime,
            activationTime: item?.activationTime,
            updatedAt: item?.updatedAt,
            userId: item?.userId,
            firstProductId: item?.firstProductId,
            type: item?.type,
            status: item?.status,
            isDeleted: item?.isDeleted,
            point: item?.point,
            customerId: item?.customerId,
            productId: item?.product ? item?.product?.id : null,
            productSerial: item?.product ? item?.product?.serial : null,
            productName: item?.product ? item?.product?.name : null,
            productType: item?.product ? item?.product?.type : null,
            modelId: item?.model ? item?.model?.id : null,
            modelName: item?.model ? item?.model?.name : null,
            modelCode: item?.model ? item?.model?.code : null,
            userShopName: item?.user?.shopName,
            userClient: item?.user?.client,
            userPhone: item?.user?.phone,
            userEmail: item?.user?.email,
            userEmployeeCode: item?.user?.employeeCode,
            userId: item?.user?.id,
            updatedByClient: item?.updatedBy?.client,
            updatedByEmail: item?.updatedBy?.email,
            updatedByEmployeeCode: item?.updatedBy?.employeeCode,
            updatedById: item?.updatedBy?.id,
            updatedByPhone: item?.updatedBy?.phone,
            updatedByShopName: item?.updatedBy?.shopName,
            programmingId: program?.id,
            programmingName: program?.name,
            programmingCode: program?.code,
            programmingApplyDates: program?.applyDates,
            programmingApplyTime: program?.applyTime,
            programmingStartDate: program?.startDate,
            programmingEndDate: program?.endDate,
            programmingConfigType: program?.configType,
            programmingRewardConditionType: program?.rewardConditionType,
            programmingRewardType: program?.rewardType,
            programmingReward: program?.reward,
            totalReward: item?.totalReward,
            programmingApply: item?.programmingApply,
            index: index,
            updatedBy: program?.updatedBy,
            industrySectorName: item?.industrySector?.name,
            industrySectorId: item?.industrySector?.id,
            industrySectorCode: item?.industrySector?.code,
            industrySectorParentId: item?.industrySector?.parentId,
            categoryName: item?.category?.name,
            categoryId: item?.category?.id,
            categoryCode: item?.category?.code,
            categoryParentId: item?.category?.parentId,
            wardId: item?.ward?.id,
            wardName: item?.ward?.name,
            wardCode: item?.ward?.code
          })

          let totalReward = 0
          flatDatas.forEach((item, index) => {
            if (item.programmingId === program?.id) {
              totalReward += item.programmingReward
            }
          })
          setFlatData(flatDatas)
        })
      })
    } else {
      setFlatData([])
    }
  }, [listTable])

  const handleGetListTable = async () => {
    try {
      const res = await getProductBonusTempListAPI(filter, page)

      if (res.data) {
        setListTable(res.data)
      }

      if (res.meta) {
        setTotal(res.meta.totalItems)
      }

      if (res.value) {
        setListProvinces(res?.value?.provinces)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const handleSelect = async (value, prop) => {
    let itemInFlatData = flatData.filter(
      (item) =>
        item.productId === prop.productId && item.userId === prop.userId && item.programmingId === prop.programmingId
    )[0].programmingApply
    let reward = itemInFlatData.filter((item) => item.ids.toString().includes(value))
    console.log('reward', reward)

    try {
      const idArr = value?.split(',')?.map(Number)
      const res = await setPriorityReconcileProductAPI({
        product_id: prop.firstProductId,
        user_id: prop.userId,
        programming_ids: idArr
      })

      let flatDataNew = [...flatData]
      flatDataNew.forEach((item, index) => {
        if (item.userId === prop.userId) {
          item.totalReward = reward[0]?.reward
        }
      })
      setFlatData(flatDataNew)
      if (res) {
        toast('Cập nhật chương trình áp dụng thành công', { type: 'success' })
      }
    } catch (error) {
      toast(error?.response?.data?.message, { type: 'error' })
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Serial',
        accessor: (cell) => {
          return `${cell.id}-${cell.productSerial}`
        },
        enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Serial {...cellProps} />
        }
      },
      {
        Header: 'Model',
        accessor: (cell) => {
          return `${cell.productSerial}-${cell.modelName}`
        },
        enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ModelName {...cellProps} />
        }
      },
      {
        Header: 'Nhóm sản phẩm',
        accessor: (cell) => {
          return `${cell.productSerial}-${cell.categoryName}`
        },
        // enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <GrString {...cellProps} />
        }
      },
      {
        Header: 'Ngành hàng',
        accessor: (cell) => {
          return `${cell.id}-${cell.industrySectorName}`
        },
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <GrString {...cellProps} />
        }
      },
      {
        Header: 'Ngày tích luỹ - kích hoạt',
        accessor: (cell) => {
          return `${cell.productSerial}!@#$${cell?.accumulationTime}!@#$${cell?.activationTime}`
        },
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        enableRowSpan: true,
        Cell: (cellProps) => {
          return <CreateDurationMoment {...cellProps} />
        }
      },
      {
        Header: 'Loại tài khoản',
        accessor: (cell) => {
          return `${cell.productSerial}-${cell.userClient}`
        },
        enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Type {...cellProps} />
        }
      },
      {
        Header: 'Tên cừa hàng/ KTV',
        accessor: (cell) => {
          return `${cell.productSerial}-${cell.userShopName}`
        },
        enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <UserShopName {...cellProps} />
        }
      },
      {
        Header: 'Mã chương trình',
        accessor: (cell) => {
          return `${cell.id}-${cell.programmingCode}`
        },
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        enableRowSpan: true,
        Cell: (cellProps) => {
          return <ProgramCode {...cellProps} />
        }
      },
      {
        Header: 'Thời gian áp dụng',
        accessor: (cell) => {
          return cell
        },
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ListStartEndDate {...cellProps} />
        }
      },
      {
        Header: 'Thưởng tạm tính',
        accessor: (cell) => {
          return `${cell.id}-${cell.programmingReward}`
        },
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ProgramReward {...cellProps} />
        }
      },
      {
        Header: 'Chương trình áp dụng',
        accessor: (cell) => {
          return cell
        },
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        enableRowSpan: true,
        Cell: (cellProps) => {
          const ListProgramingApply = cellProps?.value.programmingApply
          const list = []
          ListProgramingApply?.map((item) => {
            const idString = item?.ids
              ?.map((element) => {
                return element.toString()
              })
              .join(',')
            list.push({
              label: item.code,
              value: idString
            })
          })
          if (cellProps.value.index === 0) {
            return (
              <>
                <FormSelect
                  options={list}
                  defaultValue={cellProps.value.programmingApply[0].code}
                  onChange={(value) => {
                    handleSelect(value, cellProps?.value)
                  }}
                />
                <p className='form-label'>
                  {'Người cập nhật: ' + `${cellProps?.value?.updatedByPhone ? cellProps?.value.updatedByPhone : ''}`}
                </p>
              </>
            )
          } else {
            return <></>
          }
        }
      },
      {
        Header: 'Tổng thưởng tạm tính',
        accessor: (cell) => {
          return `${cell.id}-${cell.totalReward}`
        },
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        enableRowSpan: true,
        Cell: (cellProps) => {
          return <SumBonus {...cellProps} />
        }
      }
    ],
    [edit, listTable, flatData]
  )

  const handleCheckPoint = (id, value) => {
    if (value == null) {
      form.setFields([{ name: `${id}`, errors: ['Vui lòng nhập điểm'] }])
    } else if (handleCheckNguyen(value) == false) {
      form.setFields([{ name: `${id}`, errors: ['Điểm là số nguyên dương.'] }])
    } else {
      form.setFields([{ name: `${id}`, errors: null }])
    }
    const data = form.getFieldsValue()
    const list = [...listDataChange]
    listTable?.map((item, index) => {
      if (item.point != data[item.id]) {
        const find = list.find((x) => x.id == item.id)
        if (find) {
          find.point = data[item.id]
        } else {
          list.push({
            id: item.id,
            point: data[item.id]
          })
        }
      }
    })
    setListDataChange(list)
  }

  const handleSave = async () => {
    const check = []
    listDataChange?.map((item) => {
      if (item.point == '' || item.point == null) {
        check.push(false)
      } else {
        check.push(true)
      }
    })

    if (check.includes(false)) {
      return toast('Vui lòng nhập đẩy đủ các điểm ở các trang.', { type: 'error' })
    }

    try {
      const res = await updatePointConfigAPI({ tasks: listDataChange })

      if (res) {
        toast('Cập nhật thành công điểm.', { type: 'success' })
        setEdit(false)
        setListDataChange([])
        handleGetListTable()
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const handleCheckNguyen = (value) => {
    if (Number(value) > 0 && Number.isInteger(Number(value))) {
      return true
    } else {
      return false
    }
  }

  // const handleExport = async () => {
  //   const res = await exportByProductAPI({ ...filter })
  //   fileDownload(res, `${moment().format('yyMMDD')}_Tam tinh thuong theo san pham.xlsx`)
  // }

  const handleClickExport = async () => {
    // setLoading(true)
    // try {
    //   const res = await exportByProductAPI({ ...filter })
    //   if (res?.data?.success === false) {
    //     toast(res?.message, { type: 'error' })
    //   } else {
    //     toast('Hệ thống đang xử lý. Tệp của bạn đã được gửi tới email ' + profile?.email + ' trong vài phút', {
    //       type: 'success'
    //     })
    //   }
    // } catch (error) {
    //   toast(error.response.data.message, { type: 'error' })
    // }
    // setLoading(false)

    setLoading(true)
    try {
      const response = await exportByProductFetcherAPI({ ...filter })
      const data = response.headers.get('Content-Type')
      if (data !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        toast('Hệ thống đang xử lý. Tệp của bạn đã được gửi tới email ' + profile?.email + ' trong vài phút', {
          type: 'success'
        })
      } else {
        try {
          const blob = await response.blob() // Fetch the blob
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${moment().format('yyMMDD')}_Danh sach tam tinh thuong.xlsx`) // Tên file
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          toast('Export file thành công!', { type: 'success' })
        } catch (err) {
          console.log('err', err)
        }
      }
    } catch (error) {}
    setLoading(false)
  }

  const handleFilter = (filterValue) => {
    if (filterValue.start_date1 !== undefined && filterValue.end_date1 !== undefined) {
      filterValue.start_date = filterValue.start_date1.startOf('day').toDate()
      filterValue.end_date = filterValue.end_date1.endOf('day').toDate()
    } else {
      filterValue.start_date = undefined
      filterValue.end_date = undefined
    }

    setFilter({
      ...filterValue,
      meta: {
        'Nhập Serial': filterValue?.serial,
        'Nhập Model': filterValue?.model_names,
        'Nhập tên cửa hàng/ KTV': filterValue?.search,
        'Nhập số điện thoại': filterValue?.phone,
        'Loại chương trình': filterValue?.type,
        'Loại tài khoản': filterValue?.user_client_type,
        'Thời gian áp dụng':
          filterValue?.start_date1?.startOf('day').toDate() + ' - ' + filterValue?.end_date1?.endOf('day').toDate(),
        Miền: filterValue?.zone_names,
        Vùng: filterValue?.sub_zone_names,
        'Tỉnh/ thành phố': filterValue?.province_names,
        'Quận/ Huyện': filterValue?.district_names,
        'Phường xã': filterValue?.ward_names
      },
      sort_field: '',
      sort_order: ''
    })
    setPage(1)
  }

  const goToPage = (page) => {
    // dispatch(getBankList({ ...filter, page }))
    setPage(page)
    handleGetListTable({ ...filter, page })
    setFilter({ ...filter, page })
  }

  return (
    <div className='page-content'>
      <div className='page-title-box container-fluid'>
        <h4 className=' mb-sm-0 font-size-18 pb-4'>Tạm tính thưởng</h4>
        {isLoading ? (
          <Spinners setLoading={setLoading} />
        ) : (
          <Row>
            <Col lg='12'>
              <Card>
                <CardBody>
                  <Space size='middle' direction='vertical' className='w-100'>
                    <ProductBonusTempListFilter handleFilter={handleFilter} handleExport={handleClickExport} />
                    <div className='d-flex flex-row '>
                      <div className='mb-xxl-0 me-3 mt-2'>
                        <h5 className='mb-0 card-title flex-grow-1'>{'Danh sách tạm tính thưởng theo khu vực'}</h5>
                        {listProvinces?.map((city, index) => (
                          <button className='btn btn-light btn-rounded me-2 my-2' key={index} value={city.id}>
                            {city.name}
                          </button>
                        ))}
                      </div>
                    </div>
                    <TableRowSpan
                      columns={columns}
                      data={flatData}
                      pagination={pagination}
                      setPagination={setPagination}
                      gotoPageCustom={goToPage}
                      currentPage={page}
                      total={total}
                      isShowTotal={true}
                    />
                  </Space>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    </div>
  )
}

export default ProductBonusTempList
