import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { Col, Row, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import { HistoryVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import { exportHistoryAPI } from 'helpers/backend_helper'
import {
  HistoryIndustrySector,
  HistoryCategory,
  HistoryModel,
  Serial,
  Zone,
  StatusHistory,
  Shop,
  ActivatedDate,
  HistoryCode,
  AccountType,
  Customer,
  Store,
  Action
} from './ManageHistoryCol'
import AddEditHistory from './components/AddEditHistory'
import { getHistoryDetail, getHistoryList, setFilter, setTypePopupHistory } from 'store/actions'
import moment from 'moment'
import ProgramByAreaAndRewardTypeFilter from 'pages/ProgramBonusTemp/components/ProgramByAreaAndRewardTypeFilter'
import { TableRowSpan } from 'components/Common/TableCustom'
import { Space } from 'antd'
import ManageHistoryFilter from './components/ManageHistoryFilter'

function ManageHistory() {
  //meta title
  document.title = 'Quản lý thay đổi tích lũy-kích hoạt | HPG'

  const dispatch = useDispatch()

  const selectHistorysState = (state) => state.HistoryReducer
  const HistorysProperties = createSelector(selectHistorysState, (reducer) => ({
    historys: reducer.historys,
    loading: reducer.loading,
    total: reducer.total
  }))

  const { historys, loading, total } = useSelector(HistorysProperties)
  // Khi historys nhận được giá trị mới
  useEffect(() => {
    const convertedHistorys = convertHistorys(historys)
    setnewHistorys(convertedHistorys)
  }, [historys])

  // ...

  function convertHistorys(historys) {
    // Logic chuyển đổi historys thành một đối tượng khác
    const newObject = []
    const type = [
      {
        label: 'Tích lũy',
        value: 'accumulation'
      },
      {
        label: 'Kích hoạt',
        value: 'activation'
      },
      {
        label: 'Trưng bày',
        value: 'display'
      },
      {
        label: 'Tin tức',
        value: 'news'
      },
      {
        label: 'Khác',
        value: 'other'
      }
    ]
    historys.forEach((history, index) => {
      let obj = {
        serial: history?.firstProduct?.serial,
        model: history?.model?.name,
        type: type?.find((e) => e.value === history?.type)?.label || '--',
        store: {
          name: history?.user?.shopName ? history?.user?.shopName : history?.user?.name,
          phone: history?.user?.phone
        },
        account: { name: history?.updatedBy?.name, phone: history?.updatedBy?.phone },
        date: history?.deletedAt,
        deletedAt: history?.deletedAt
      }
      newObject.push(obj)
    })
    return newObject
  }

  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (layout) => ({
    filter: layout.filter
  }))
  const { filter } = useSelector(LayoutProperties)

  const [isLoading, setLoading] = useState(loading)
  const [showDeleteAccount, setShowDeleteAccount] = useState(false)
  const [selected, setSelected] = useState()
  const [showForm, setShowForm] = useState(false)
  const [page, setPage] = useState(1)
  const [newHistorys, setnewHistorys] = useState()
  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: 10,
    totalRows: total
  })

  useEffect(() => {
    dispatch(setFilter({ page: 1 }))
    dispatch(getHistoryList({ page: 1 }))
  }, [dispatch])

  const onClickDelete = (item) => {
    setSelected(item)
    setShowDeleteAccount(true)
  }

  const onClickItem = async (item, type) => {
    setSelected(item)
    dispatch(setTypePopupHistory(type))
    dispatch(getHistoryDetail(item))
    setShowForm(true)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Serial',
        accessor: 'serial',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Serial {...cellProps} />
        }
      },
      {
        Header: 'Model',
        accessor: 'model',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <HistoryModel {...cellProps} />
        }
      },
      {
        Header: 'Loại',
        accessor: 'type',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <HistoryCategory {...cellProps} />
        }
      },
      {
        Header: 'Cửa hàng',
        accessor: 'store',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Store {...cellProps} />
        }
      },
      {
        Header: 'Tài khoản thực hiện',
        accessor: 'account',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Store {...cellProps} />
        }
      },
      {
        Header: 'Ngày thực hiện',
        accessor: 'date',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ActivatedDate {...cellProps} />
        }
      },
      {
        Header: 'Thao tác',
        accessor: (cell) => {
          return cell
        },
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Action {...cellProps} />
        }
      }
    ],
    [filter]
  )

  const setGlobalFilterUser = async (data) => {
    if (data.start_date1 !== undefined && data.end_date1 !== undefined) {
      data.start_date = data.start_date1.startOf('day').toDate()
      data.end_date = data.end_date1.endOf('day').toDate()
    } else {
      data.start_date = undefined
      data.end_date = undefined
    }

    dispatch(
      getHistoryList({
        ...data,
        meta: {
          'Nhập model': data?.model_names,
          'Nhập serial': data?.search,
          Loại: data?.type_name,
          'Số điện thoại cửa hàng': data?.user,
          'Nhập tên nhân viên, SĐT': data?.updated_by,
          'Thời gian thực hiện':
            data?.start_date1?.startOf('day').toDate() + ' - ' + data?.end_date1?.endOf('day').toDate()
        }
      })
    )
    dispatch(setFilter({ ...data, page: 1 }))
    setPage(1)
  }

  const goToPage = (page) => {
    dispatch(getHistoryList({ ...filter, page }))
    setPage(page)
  }

  const onDeleteAccount = async () => {
    try {
      const res = await deleteHistoryAPI({
        id: selected.userRewardId
      })
      if (res) {
        toast(res.message, { type: 'success' })
        setShowDeleteAccount(false)
        dispatch(getHistoryList({ ...filter, page: page }))
        setPage(page)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const handleClickExport = async () => {
    setLoading(true)
    try {
      const res = await exportHistoryAPI(filter)
      if (res) {
        const nameFile = `${moment().format('yyMMDD')}_Danh sach thay doi tich luy - kich hoat.xlsx`
        const url = window.URL.createObjectURL(new Blob([res]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', nameFile) //or any other extension
        document.body.appendChild(link)
        link.click()
        toast('Export file thành công!', { type: 'success' })
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
    setLoading(false)
  }

  const handleResetFilter = () => {
    dispatch(setFilter({ page: 1 }))
    dispatch(getHistoryList({ page: 1 }))
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{HistoryVN.title}</h4>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody>
                    <Space size='middle' direction='vertical' className='w-100'>
                      <ManageHistoryFilter
                        handleFilter={setGlobalFilterUser}
                        handleResetFilter={handleResetFilter}
                        handleExport={handleClickExport}
                      />
                      <TableRowSpan
                        columns={columns}
                        data={newHistorys}
                        pagination={pagination}
                        setPagination={setPagination}
                        gotoPageCustom={goToPage}
                        currentPage={page}
                        total={total}
                        isShowTotal={true}
                      />
                    </Space>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ConfirmModal
        show={showDeleteAccount}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-alert'></i>
          </div>
        }
        onConfirmClick={onDeleteAccount}
        onCloseClick={() => setShowDeleteAccount(false)}
        title='Xóa bản ghi'
        description={`Các kết quả thưởng của bản ghi này và bản kích hoạt liên quan (nếu có) sẽ đồng thời bị hủy.\n Bạn có chắc chắn muốn xoá bản ghi kích hoạt?`}
      />
      <AddEditHistory
        onCloseClick={() => {
          setShowForm(false)
        }}
        show={showForm}
      />
    </React.Fragment>
  )
}

export default ManageHistory
